import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/store';
import Channel from 'models/channel';
import ScheduleEntry from 'models/scheduleEntry';
import Video from 'models/video';
import VideoState from 'models/videoState';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token.encoded}`);
      }

      headers.set('Content-Type', 'application/json');

      return headers;
    }
  }),
  tagTypes: ['Channel', 'YouTubeChannel', 'Schedule', 'Video', 'Watching', 'Hidden', 'VideoState'],
  endpoints: (build) => ({
    // Get channels.
    getChannels: build.query<Channel[], void>({
      query: () => 'channels',
      providesTags: ['Channel']
    }),

    // Get YouTube channels.
    getYouTubeChannels: build.query<Channel[], void>({
      query: () => 'channels/youtube',
      providesTags: ['YouTubeChannel']
    }),

    // Get schedule.
    getSchedule: build.query<ScheduleEntry[], void>({
      query: () => 'schedule',
      providesTags: ['Schedule']
    }),

    // Get videos.
    getVideos: build.query<Video[], number | undefined>({
      query: (channelId) => {
        return channelId ? `videos?channel_id=${channelId}` : 'videos';
      },
      providesTags: ['Video']
    }),

    // Get watching.
    getWatchingVideos: build.query<Video[], void>({
      query: () => 'videos/watching',
      providesTags: ['Watching']
    }),

    // Get hidden.
    getHiddenVideos: build.query<Video[], void>({
      query: () => 'videos/hidden',
      providesTags: ['Hidden']
    }),

    // Get video.
    getVideo: build.query<Video, string>({
      query: (id) => `videos/${id}`,
      providesTags: ['Video']
    }),

    // Delete video.
    deleteVideo: build.mutation<number, Pick<Video, 'id'>>({
      query(video) {
        return {
          url: `videos/${video.id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Video']
    }),

    // Get video state.
    getVideoState: build.query<VideoState, string>({
      query: (id) => `video_states/${id}`,
      providesTags: ['VideoState']
    }),

    // Update video state.
    updateVideoState: build.mutation<VideoState, VideoState>({
      query(videoState) {
        return {
          url: `video_states/${videoState.video_id}`,
          method: 'PUT',
          body: videoState
        };
      },
      invalidatesTags: ['Video', 'VideoState', 'Hidden']
    })
  })
});

export const {
  useGetChannelsQuery,
  useGetYouTubeChannelsQuery,
  useGetScheduleQuery,
  useGetVideosQuery,
  useGetWatchingVideosQuery,
  useGetHiddenVideosQuery,
  useGetVideoQuery,
  useDeleteVideoMutation,
  useGetVideoStateQuery,
  useLazyGetVideoStateQuery,
  useUpdateVideoStateMutation
} = api;
