import TwitchBadge from 'components/shared/TwitchBadge';
import YouTubeBadge from 'components/shared/YouTubeBadge';
import ChannelModel from 'models/channel';
import { bestURL } from 'models/imageSet';

type Props = {
  channel: ChannelModel;
};

export default function Channel(props: Props) {
  const { channel } = props;

  return (
    <div className="card flex flex-col md:flex-row content-start md:items-center space-y-4 md:space-y-0 md:space-x-4 p-4">
      <img
        className="w-32 h-32 rounded-md"
        src={bestURL(channel.thumbnail)?.toString()}
        alt={`${channel.title} thumbnail`}
      />

      <div className="">
        <h4 className="font-bold">{channel.title}</h4>
        <p>{channel.description}</p>

        {channel.source == 'youtube' && <YouTubeBadge />}
        {channel.source == 'twitch' && <TwitchBadge />}
      </div>
    </div>
  );
}
