import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from 'typescript-cookie';
import authReducer, { AppJwtPayload } from 'common/authSlice';
import settingsReducer, { Theme } from 'common/settingsSlice';
import { api } from 'common/api';
import { listenerMiddleware, startAppListening } from 'common/listenerMiddleware';

function fetchToken(): { encoded: string; decoded: AppJwtPayload } | null {
  const encoded = getCookie('token');
  if (encoded) {
    const decoded = jwtDecode<AppJwtPayload>(encoded);
    if (decoded) {
      return { encoded, decoded };
    }
  }
  return null;
}

function fetchTheme(): Theme {
  let theme: Theme;
  const json = localStorage.getItem('settings');
  if (json) {
    theme = JSON.parse(json).theme;
  }
  theme = Theme.UseSystem;
  applyTheme(theme);
  return theme;
}

function applyTheme(theme: Theme) {
  let themeClass;
  switch (theme) {
    case Theme.UseSystem:
      themeClass = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      break;
    case Theme.Dark:
      themeClass = 'dark';
      break;
    case Theme.Light:
      themeClass = 'light';
      break;
  }
  document.documentElement.classList.remove('dark');
  document.documentElement.classList.remove('light');
  document.documentElement.classList.add(themeClass);
}

startAppListening({
  predicate: (action, currentState, previousState) => {
    return currentState.settings.theme !== previousState.settings.theme;
  },
  // Listeners can have long-running async workflows
  effect: async (action, listenerApi) => {
    const theme = listenerApi.getState().settings.theme;
    applyTheme(theme);
  }
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsReducer,
    [api.reducerPath]: api.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat(api.middleware),
  // Preload state.
  preloadedState: {
    auth: {
      token: fetchToken()
    },
    settings: {
      theme: fetchTheme()
    }
  }
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
