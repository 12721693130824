import { addListener, createListenerMiddleware, TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from 'app/store';

// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;
