import { Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren, useEffect } from 'react';

type Props = {
  className?: string;
  isVisible: boolean;
  closeMenu: () => void;
};

export default function NavMenu(props: PropsWithChildren<Props>) {
  const { children, className, isVisible, closeMenu } = props;

  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  });

  return (
    <Transition appear show={isVisible} className={`fixed inset-0 overscroll-contain ${className}`}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto" onClick={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="-left-[calc(100%-3rem)] sm: -left-96"
          enterTo="left-0"
          leave="ease-in duration-200"
          leaveFrom="left-0"
          leaveTo="-left-[calc(100%-3rem)] sm:-left-96"
        >
          <div className="fixed top-0 left-0 w-[calc(100%-3rem)] sm:w-96 h-full z-100">{children}</div>
        </Transition.Child>
      </div>
    </Transition>
  );
}
