import { useAppDispatch, useAppSelector } from 'common/hooks';
import { signOut } from 'common/authSlice';

function AccountSection(props: { title: string; subtitle: string | undefined }) {
  return (
    <div className="pb-4">
      <h2 className="text-md font-medium">{props.title}</h2>
      <p className="text-sm text-zinc-500 dark:text-zinc-400">
        {props.subtitle && props.subtitle != '' ? props.subtitle : 'N/A'}
      </p>
    </div>
  );
}

export default function Account() {
  const token = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();

  return (
    <section>
      {token !== null && (
        <div className="card p-4">
          <h1 className="text-xl font-bold pb-4">Account</h1>

          <AccountSection title="Email" subtitle={token.decoded?.email} />
          <AccountSection title="Issuer" subtitle={token.decoded?.iss} />
          <AccountSection title="Subject" subtitle={token.decoded?.sub} />
          <AccountSection title="Roles" subtitle={token.decoded?.roles?.join(', ')} />

          <div className="pb-4">
            <h2 className="font-bold">Token</h2>
            <pre className="p-2 bg-black overflow-x-auto text-white text-xs rounded-md shadow-lg">{token.encoded}</pre>
          </div>

          <button onClick={() => dispatch(signOut())} className="btn-destructive-lg">
            Sign Out
          </button>
        </div>
      )}
    </section>
  );
}
