import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setTheme, Theme } from 'common/settingsSlice';
import ListBox from 'components/shared/controls/ListBox';

export default function Appearance() {
  const settings = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  return (
    <section>
      <div className="card p-4">
        <h1 className="text-xl font-bold pb-4">Appearance</h1>

        <ListBox
          label="Theme"
          value={settings.theme}
          values={Object.values(Theme)}
          onChange={(theme) => dispatch(setTheme(theme))}
        />
      </div>
    </section>
  );
}
