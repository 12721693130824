import { Transition } from '@headlessui/react';

export default function ErrorMessage(props: { isVisible: boolean; message: string; className?: string }) {
  const { isVisible, message, className } = props;

  return (
    <Transition
      show={isVisible}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`${className} flex justify-center`}>
        <div className="px-4 py-2 max-w-sm mx-auto bg-red-600 rounded-full shadow-lg text-white text-sm">{message}</div>
      </div>
    </Transition>
  );
}
