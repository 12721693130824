import { Link } from 'react-router-dom';
import Video from 'models/video';
import { useDeleteVideoMutation, useUpdateVideoStateMutation } from 'common/api';
import { useAppSelector } from 'common/hooks';
import ProgressBar from './ProgressBar';
import { Alignment, ContextMenuItem, ContextMenuItems, ContextMenuLink } from 'components/shared/controls/ContextMenu';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import { useCallback } from 'react';

export default function VideoCard(props: { video: Video }) {
  const { video } = props;
  const formattedDate = moment(video.published_at).fromNow();
  const token = useAppSelector((state) => state.auth.token);
  const [updateState, { isLoading: isHiding }] = useUpdateVideoStateMutation();
  const [deleteVideo, { isLoading: isDeleting }] = useDeleteVideoMutation();

  const hideVideo = useCallback(
    (is_hidden: boolean) => {
      updateState({
        video_id: Number(video.id),
        is_hidden: is_hidden
      });
    },
    [video.id]
  );

  return (
    <div className="group flex flex-col">
      <Link to={`/videos/${video.id}`} className="flex mb-4">
        <div
          className="group/image rounded-lg w-full aspect-video bg-black bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: `url(https://i.ytimg.com/vi/${video.source_id}/sddefault.jpg)` }}
        >
          <div className="flex flex-col group-hover/image:bg-black/50 w-full h-full rounded-lg border border-white/20">
            {video.progress && (
              <ProgressBar className="mt-auto mx-[2px] mb-[1px]" progress={video.progress} total={video.duration} />
            )}
          </div>
        </div>
      </Link>

      <div className="flex flex-row justify-between gap-1">
        <h4 className="font-medium">{video.title}</h4>

        <Menu as="div" className="relative inline-block">
          {({ open }) => (
            <div className={`${!open && 'pointer-fine:invisible group-hover:visible'}`}>
              <Menu.Button className="align-middle items-center w-6 h-6 rounded-full bg-zinc-200 dark:bg-zinc-800 hover:bg-zinc-300 dark:hover:bg-zinc-700 text-zinc-600 focus:outline-none">
                <FontAwesomeIcon
                  icon={faEllipsis}
                  size="sm"
                  className="text-zinc-900 dark:text-zinc-100 w-6"
                  fixedWidth
                />
              </Menu.Button>
              {open && (
                <ContextMenuItems static alignment={Alignment.Trailing}>
                  <ContextMenuLink title="Open in YouTube" href={`https://youtube.com/watch?v=${video.source_id}`} />
                  {token && (
                    <>
                      <ContextMenuItem title="Mark as Played" />
                      {video.is_hidden && (
                        <ContextMenuItem title="Unhide" disabled={isHiding} onClick={() => hideVideo(false)} />
                      )}
                      {!video.is_hidden && (
                        <ContextMenuItem title="Hide" disabled={isHiding} onClick={() => hideVideo(true)} />
                      )}
                    </>
                  )}
                  {token?.decoded.roles?.includes('admin') && (
                    <ContextMenuItem title="Delete" disabled={isDeleting} onClick={() => deleteVideo(video)} />
                  )}
                </ContextMenuItems>
              )}
            </div>
          )}
        </Menu>
      </div>

      <p className="text-sm font-medium text-zinc-500 dark:text-zinc-500">{formattedDate}</p>
    </div>
  );
}
