import ScheduleEntryModel from 'models/scheduleEntry';
import moment from 'moment';

export default function ScheduleEntry(props: { entry: ScheduleEntryModel }) {
  const { entry } = props;
  const relativeDate = moment(entry.start_time).fromNow();
  const absoluteDate = moment(entry.start_time).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <div className="py-4">
      <h3 className="text-md font-medium">{entry.title}</h3>
      <p className="group inline-block text-sm text-zinc-500 dark:text-zinc-400">
        <span>{relativeDate}</span>
        <span className="hidden group-hover:inline"> ({absoluteDate})</span>
      </p>
    </div>
  );
}
