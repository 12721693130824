import Video from 'models/video';

type Props = {
  video: Video;
  onClick(): void;
};

export default function PlayerPlaceholder(props: Props) {
  const { video, onClick } = props;

  return (
    <div
      className="group grid rounded-xl w-full aspect-video bg-black bg-no-repeat bg-cover bg-center cursor-pointer place-items-center"
      style={{ backgroundImage: `url(https://i.ytimg.com/vi/${video.source_id}/maxresdefault.jpg)` }}
      onClick={() => onClick()}
    >
      <button className="bg-youtube-play w-[68px] h-[48px] filter grayscale group-hover:filter-none ease-in-out duration-300" />
    </div>
  );
}
