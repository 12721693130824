import { useGetWatchingVideosQuery } from 'common/api';
import ErrorMessage from 'components/shared/status/ErrorMessage';
import Loading from 'components/shared/status/Loading';
import VideoCard from './VideoCard';

export default function Watching() {
  const { data, error, isLoading } = useGetWatchingVideosQuery();

  return (
    <div>
      <Loading isVisible={isLoading} />
      <ErrorMessage isVisible={!isLoading && error != undefined} message="Error loading videos!" />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {!isLoading &&
          !error &&
          data &&
          data.map((video) => {
            return <VideoCard key={video.id} video={video} />;
          })}
      </div>
    </div>
  );
}
