import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type Props<T> = {
  value: T;
  values: T[];
  onChange: (value: T) => void;
  label?: string;
};

export default function ListBox<T extends string>(props: Props<T>) {
  const { value, values, onChange, label } = props;

  return (
    <div className="flex gap-4 items-center">
      <Listbox value={value} onChange={onChange}>
        {label && <Listbox.Label className="font-sm font-medium">{label}</Listbox.Label>}
        <div className="relative">
          <Listbox.Button className="relative w-48 cursor-default rounded-lg bg-zinc-50 dark:bg-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-600 border border-zinc-300 dark:border-zinc-600 py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm">
            <span className="block truncate">{value.toString()}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            </span>
          </Listbox.Button>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute p-1 mt-1 w-64 overflow-auto rounded-md bg-white dark:bg-zinc-700 border border-zinc-300 dark:border-zinc-600 text-base shadow-sm focus:outline-none sm:text-sm">
              {values.map((value, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `group relative cursor-default select-none rounded-md p-2 pl-10 text-sm ${
                      active ? 'bg-rose-600 text-white' : 'text-zinc-900 dark:text-zinc-100'
                    }`
                  }
                  value={value}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{value}</span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-rose-600'
                          }`}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
