import { PropsWithChildren } from 'react';
import { Menu } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavLink } from 'react-router-dom';

export enum Alignment {
  Leading,
  Trailing
}

type MenuProps = {
  className?: string;
  icon?: IconProp;
};

export function ContextMenu(props: PropsWithChildren<MenuProps>) {
  const { children, className } = props;

  return (
    <Menu as="div" className={`relative inline-block ${className}`}>
      {children}
    </Menu>
  );
}

type ButtonProps = {
  icon?: IconProp;
};

export function ContextMenuButton(props: ButtonProps) {
  const { icon } = props;
  return (
    <Menu.Button className="grid place-content-center w-7 h-7 rounded-full bg-slate-200 hover:bg-slate-300 text-slate-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
      {icon && <FontAwesomeIcon icon={icon} />}
    </Menu.Button>
  );
}

type MenuItemsProps = {
  className?: string;
  alignment?: Alignment;
  static?: boolean;
};

export function ContextMenuItems(props: PropsWithChildren<MenuItemsProps>) {
  const { children, className, alignment, static: isStatic } = props;
  const trailing = alignment == Alignment.Trailing;

  return (
    <Menu.Items
      static={isStatic}
      className={`absolute ${
        trailing ? 'right-0 origin-top-right' : 'left-0 origin-top-left'
      }  mt-2 w-56 divide-y divide-gray-1 00 rounded-md bg-white dark:bg-zinc-700 border border-zinc-300 dark:border-zinc-600 shadow-sm focus:outline-none ${className}`}
    >
      <div className="p-1">{children}</div>
    </Menu.Items>
  );
}

type MenuItemProps = {
  className?: string;
  icon?: IconProp;
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export function ContextMenuItem(props: MenuItemProps) {
  const { className, icon, title, onClick, disabled } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          disabled={disabled}
          onClick={onClick}
          className={`${
            active ? 'bg-rose-600 text-white' : 'text-zinc-900 dark:text-zinc-100'
          } group flex w-full items-center rounded-md p-2 text-sm ${className}`}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {title}
        </button>
      )}
    </Menu.Item>
  );
}

type MenuItemLinkProps = {
  className?: string;
  icon?: IconProp;
  title?: string;
  href: string;
};

export function ContextMenuLink(props: MenuItemLinkProps) {
  const { className, icon, title, href } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <a
          href={href}
          className={`${
            active ? 'bg-rose-600 text-white' : 'text-zinc-900 dark:text-zinc-100'
          } group flex w-full items-center rounded-md p-2 text-sm ${className}`}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {title}
        </a>
      )}
    </Menu.Item>
  );
}

export function ContextMenuNavLink(props: MenuItemLinkProps) {
  const { className, icon, title, href } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <NavLink
          to={href}
          className={`${
            active ? 'bg-rose-600 text-white' : 'text-zinc-900 dark:text-zinc-100'
          } group flex w-full items-center rounded-md p-2 text-sm ${className}`}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {title}
        </NavLink>
      )}
    </Menu.Item>
  );
}
