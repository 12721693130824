import Channel from 'models/channel';
import { NavLink } from 'react-router-dom';

function ScopeLink(props: { to: string; title: string; end?: boolean }) {
  const { to, title, end } = props;

  return (
    <div className="snap-start scroll-ml-4 shrink-0 first:pl-4 md:first:pl-6 last:pr-4 md:last:pr-6">
      <NavLink
        end={end ?? false}
        to={to}
        className={({ isActive }) => (isActive ? 'btn-discrete-active' : 'btn-discrete') + ' inline-block'}
      >
        {title}
      </NavLink>
    </div>
  );
}

export default function ChannelsScopeBar(props: { channels?: Channel[] }) {
  const { channels } = props;

  return (
    <div className="flex gap-2 -mx-4 md:-mx-6 snap-x overflow-x-auto pb-4 hide-scrollbar ">
      <ScopeLink to={'/videos'} title="All Channels" end />
      {channels &&
        channels.map((channel) => {
          return <ScopeLink key={channel.id} to={`/videos/channel/${channel.id}`} title={channel.title} />;
        })}
    </div>
  );
}
