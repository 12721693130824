import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TwitchBadge() {
  return (
    <span className="rounded-md px-2 py-1 bg-twitch text-white text-sm font-medium">
      <FontAwesomeIcon icon={faTwitch} className="mr-2" />
      Twitch
    </span>
  );
}
