import Video from 'models/video';
import VideoCard from './VideoCard';

type Props = {
  videos: Video[];
};

export default function VideosGrid(props: Props) {
  const { videos } = props;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
      {videos && videos.map((video) => <VideoCard key={video.id} video={video} />)}
    </div>
  );
}
