function formatText(text: string): string {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export default function FormattedText(props: { text: string; className?: string }) {
  const { text, className } = props;
  const formatted = formatText(text);

  return <div className={className} dangerouslySetInnerHTML={{ __html: formatted }} />;
}
