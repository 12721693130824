import { skipToken } from '@reduxjs/toolkit/query';
import { useGetVideoStateQuery } from 'common/api';
import Video from 'models/video';
import FormattedText from '../shared/controls/FormattedText';

function DetailCell(props: { title: string; subtitle: string }) {
  const { title, subtitle } = props;

  return (
    <div className="py-2 flex flex-row justify-between">
      <span className="font-sm">{title}</span>
      <span className="font-sm text-zinc-500 dark:text-zinc-400">{subtitle}</span>
    </div>
  );
}

export default function VideoDetails(props: { video: Video }) {
  const { video } = props;
  const { data: videoState } = useGetVideoStateQuery(video.id.toString() ?? skipToken);

  return (
    <div>
      <h1 className="pb-4 text-xl font-bold">{video.title}</h1>
      <FormattedText text={video.description} />

      {videoState && (
        <div className="card mt-4 p-4 grid grid-cols-1 divide-y divide-zinc-200 dark:divide-zinc-700">
          <h2 className="font-medium mb-4">Debug</h2>
          <DetailCell title="Saved Progress" subtitle={videoState.progress?.toString() ?? 'None'} />
          <DetailCell title="Is Hidden?" subtitle={videoState.is_hidden ? 'true' : 'false'} />
        </div>
      )}
    </div>
  );
}
