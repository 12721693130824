/** A set of images. */
export type ImageSet = {
  low?: URL;
  medium?: URL;
  high?: URL;
};

/** Enumerate the best image URL from an image set. */
export function bestURL(image_set: ImageSet | undefined): URL | undefined {
  if (image_set?.high != undefined) {
    return image_set.high;
  } else if (image_set?.medium != undefined) {
    return image_set?.medium;
  } else if (image_set?.low != undefined) {
    return image_set?.low;
  }
}

export default ImageSet;
