import { Routes, Route } from 'react-router-dom';
import Account from 'components/settings/Account';
import Channels from 'components/channels/Channels';
import NavBar from 'components/navigation/NavBar';
import Videos from 'components/videos/Videos';
import Video from 'components/videos/Video';
import Watching from 'components/videos/Watching';
import VideosLayout from 'components/videos/VideosLayout';
import Home from 'components/home/Home';
import Hidden from 'components/videos/Hidden';
import Schedule from 'components/schedule/Schedule';
import Settings from 'components/settings/Settings';
import Appearance from 'components/settings/Appearance';

export default function App() {
  return (
    <div>
      <NavBar />

      <section className="p-4 sm:p-6">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/watching" element={<Watching />} />
          <Route path="/hidden" element={<Hidden />} />
          <Route path="/channels" element={<Channels />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/settings" element={<Settings />}>
            <Route path="/settings/account" element={<Account />} />
            <Route path="/settings/appearance" element={<Appearance />} />
          </Route>
          <Route path="/videos" element={<VideosLayout />}>
            <Route index element={<Videos />} />
            <Route path="channel/:channel_id" element={<Videos />} />
          </Route>
          <Route path="/videos/:id" element={<Video />} />
        </Routes>
      </section>
    </div>
  );
}
