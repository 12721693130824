import { faDiscord, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type Props = {
  isVisible: boolean;
  closeModal: () => void;
};

export default function AuthDialog(props: Props) {
  const { isVisible, closeModal } = props;

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl divide-y divide-dashed bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="flex justify-between items-center p-4">
                  <h1 className="text-lg font-medium text-gray-900">Sign In</h1>
                  <button
                    className="place-content-center w-7 h-7 rounded-full bg-slate-100 hover:bg-slate-200 text-slate-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    onClick={closeModal}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </Dialog.Title>

                <div className="p-6">
                  <Dialog.Description className="text-gray-600">
                    Sign in to save video progress (and other cool stuff coming soon!).
                  </Dialog.Description>

                  <div className="mt-4 flex flex-row gap-4">
                    <a href="/api/auth/google" className="btn-primary-lg grow bg-google hover:bg-google/90">
                      <FontAwesomeIcon icon={faGoogle} className="mr-2" />
                      <span>Google</span>
                    </a>

                    <a href="/api/auth/discord" className="btn-primary-lg grow bg-discord hover:bg-discord/90">
                      <FontAwesomeIcon icon={faDiscord} className="mr-2" />
                      Discord
                    </a>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
