import { Transition } from '@headlessui/react';

export default function Loading(props: { isVisible: boolean; className?: string }) {
  const { isVisible, className } = props;

  return (
    <Transition
      show={isVisible}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`${className} flex justify-center absolute left-1/2 transform -translate-x-1/2 mt-4`}>
        <div className="relative h-1 w-48 max-w-sm bg-rose-600/20 rounded-full">
          <div className="absolute h-1 w-8 bg-rose-600 rounded-full animate-kitt" />
        </div>
      </div>
    </Transition>
  );
}
