import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetVideoQuery } from 'common/api';
import Player from 'components/player/Player';
import VideoDetails from './VideoDetails';
import ErrorMessage from 'components/shared/status/ErrorMessage';
import Loading from 'components/shared/status/Loading';

export default function Video() {
  const { id } = useParams();
  const { data: video, error, isLoading } = useGetVideoQuery(id ?? skipToken);

  return (
    <div>
      <Loading isVisible={isLoading} />
      <ErrorMessage isVisible={!isLoading && error != undefined} message="Error loading video details!" />

      {!isLoading && !error && video && (
        <div className="md:flex gap-4">
          <div className="md:flex-auto md:basis-8/12 mb-4">
            <Player video={video} />
          </div>
          <div className="md:flex-none md:basis-4/12">
            <VideoDetails video={video} />
          </div>
        </div>
      )}
    </div>
  );
}
