import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function YouTubeBadge() {
  return (
    <span className="rounded-md px-2 py-1 bg-youtube text-white text-sm font-medium">
      <FontAwesomeIcon icon={faYoutube} className="mr-2" />
      YouTube
    </span>
  );
}
