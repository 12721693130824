import { Outlet } from 'react-router-dom';
import { useGetYouTubeChannelsQuery } from 'common/api';
import ErrorMessage from 'components/shared/status/ErrorMessage';
import Loading from 'components/shared/status/Loading';
import ChannelsScopeBar from './ChannelsScopeBar';

export default function VideosLayout() {
  const { data, error, isLoading } = useGetYouTubeChannelsQuery();

  return (
    <div>
      <Loading isVisible={isLoading} />
      <ErrorMessage isVisible={!isLoading && error != undefined} message="Error loading videos!" />

      <ChannelsScopeBar channels={data} />

      <Outlet />
    </div>
  );
}
