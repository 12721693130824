import YouTube, { YouTubePlayer } from 'react-youtube';

type Props = {
  id: string;
  title: string;
  initialProgress: number;
  onReady(player: YouTubePlayer): void;
};

export default function PlayerContent(props: Props) {
  const { id, title, initialProgress, onReady } = props;

  const opts = {
    width: null,
    height: null,
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      autoplay: 1,
      start: initialProgress,
      origin: window.location.href
    }
  };

  return (
    <YouTube
      opts={opts}
      videoId={id}
      className="flex-auto basis-8/12 rounded-xl w-full aspect-video bg-black"
      iframeClassName="rounded-xl w-full aspect-video"
      title={title}
      onReady={onReady}
    />
  );
}
