import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Theme {
  UseSystem = 'use_system',
  Dark = 'dark',
  Light = 'light'
}

export type SettingsState = {
  theme: Theme;
};

export const initialState: SettingsState = {
  theme: Theme.UseSystem
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
      localStorage.setItem('settings', JSON.stringify(state));
    }
  }
});

export const { setTheme } = settingsSlice.actions;

export default settingsSlice.reducer;
