import { useGetVideosQuery } from 'common/api';
import ErrorMessage from 'components/shared/status/ErrorMessage';
import Loading from 'components/shared/status/Loading';
import VideosGrid from './VideosGrid';
import { useParams } from 'react-router-dom';

export default function Videos() {
  const { channel_id } = useParams();
  const channelId: number | undefined = channel_id ? parseInt(channel_id) : undefined;
  const { data: videos, error, isLoading } = useGetVideosQuery(channelId);

  return (
    <div>
      <Loading isVisible={isLoading} />
      <ErrorMessage isVisible={!isLoading && error != undefined} message="Error loading videos!" />

      {videos && <VideosGrid videos={videos} />}
    </div>
  );
}
