import { useCallback, useEffect, useState } from 'react';
import PlayerContent from 'components/player/PlayerContent';
import Video from 'models/video';
import PlayerPlaceholder from 'components/player/PlayerPlaceholder';
import YouTube, { YouTubePlayer } from 'react-youtube';
import { useGetVideoStateQuery, useUpdateVideoStateMutation } from 'common/api';

let youtubePlayer: YouTubePlayer | null = null;

export default function Player(props: { video: Video }) {
  const { video } = props;
  const [hasInitialized, setHasInitialized] = useState(false);
  const [initialProgress, setInitialProgress] = useState(0);
  const { data: videoState } = useGetVideoStateQuery(video.id.toString(), { skip: hasInitialized });

  useEffect(() => {
    if (videoState) {
      setInitialProgress(videoState.progress ?? 0);
    }
  }, [videoState]);

  return (
    <div className="rounded-xl w-full aspect-video bg-black">
      {hasInitialized && (
        <div>
          <SyncProgress video_id={video.id} />
          <PlayerContent
            id={video.source_id}
            title={video.title}
            initialProgress={initialProgress}
            onReady={(event) => {
              youtubePlayer = event.target;
            }}
          />
        </div>
      )}
      {!hasInitialized && <PlayerPlaceholder video={video} onClick={() => setHasInitialized(true)} />}
    </div>
  );
}

function SyncProgress(props: { video_id: number }) {
  const { video_id } = props;
  const [updateProgress] = useUpdateVideoStateMutation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (youtubePlayer && youtubePlayer.getPlayerState() == YouTube.PlayerState.PLAYING) {
        syncProgress(youtubePlayer.getCurrentTime());
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  const syncProgress = useCallback(
    (progress: number) => {
      updateProgress({
        video_id: Number(video_id),
        progress: Math.round(progress),
        is_hidden: false
      });
    },
    [video_id]
  );

  return <div></div>;
}
