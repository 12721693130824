import { JwtPayload } from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import { removeCookie } from 'typescript-cookie';

export interface AppJwtPayload extends JwtPayload {
  email?: string;
  roles?: string[];
}

export type AuthState = {
  token: { encoded: string; decoded: AppJwtPayload } | null;
};

export const initialState: AuthState = {
  token: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signOut: (state) => {
      removeCookie('token');
      state.token = null;
    }
  }
});

export const { signOut } = authSlice.actions;

export default authSlice.reducer;
