type Props = {
  progress: number;
  total: number;
  className?: string;
};

export default function ProgressBar(props: Props) {
  const { progress, total, className } = props;

  return (
    <div className={`bg-black/50 rounded-full ${className}`}>
      <div className=" bg-rose-600 h-1 rounded-full" style={{ width: `${(progress / total) * 100}%` }} />
    </div>
  );
}
