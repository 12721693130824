import { NavLink, Outlet } from 'react-router-dom';

function MenuItem(props: { to: string; title: string }) {
  const { to, title } = props;

  return (
    <li className="mb-2">
      <NavLink to={to} className={({ isActive }) => (isActive ? 'btn-primary' : 'btn-discrete') + ' block'}>
        {title}
      </NavLink>
    </li>
  );
}

export default function VideosLayout() {
  return (
    <section className="flex gap-6">
      <ul className="shrink-0 w-64">
        <MenuItem to="/settings/appearance" title="Appearance" />
        <MenuItem to="/settings/account" title="Account" />
      </ul>

      <div className="flex-1 min-w-0">
        <Outlet />
      </div>
    </section>
  );
}
