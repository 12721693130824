import { useGetChannelsQuery } from 'common/api';
import ErrorMessage from 'components/shared/status/ErrorMessage';
import Loading from 'components/shared/status/Loading';
import Channel from './Channel';

export default function Channels() {
  const { data, error, isLoading } = useGetChannelsQuery();

  return (
    <div>
      <Loading isVisible={isLoading} />
      <ErrorMessage isVisible={!isLoading && error != undefined} message="Error loading channels!" />

      <div>
        {!isLoading &&
          !error &&
          data &&
          data.map((channel) => {
            return (
              <div className="mb-4">
                <Channel key={channel.id} channel={channel} />
              </div>
            );
          })}
      </div>
    </div>
  );
}
