import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { signOut } from 'common/authSlice';
import { Menu } from '@headlessui/react';
import { useState } from 'react';
import AuthDialog from './AuthDialog';
import {
  Alignment,
  ContextMenuItem,
  ContextMenuItems,
  ContextMenuNavLink
} from 'components/shared/controls/ContextMenu';
import NavMenu from './NavMenu';

function AppNavLink(props: { to: string; title: string }) {
  const { to, title } = props;

  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? 'btn-primary' : 'btn-discrete') + ' block'}>
      {title}
    </NavLink>
  );
}

export default function NavBar() {
  const token = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function openMenu() {
    setIsMenuOpen(true);
  }

  function closeMenu() {
    setIsMenuOpen(false);
  }

  function openModal() {
    setIsDialogOpen(true);
  }

  function closeModal() {
    setIsDialogOpen(false);
  }

  return (
    <>
      <AuthDialog isVisible={isDialogOpen} closeModal={closeModal} />

      <header className="px-3 sm:px-6 p-4 sm:p-6 sticky top-0 flex flex-row items-center justify-between bg-white dark:bg-zinc-900 drop-shadow-sm border-b border-zinc-200 dark:border-zinc-800 z-10">
        <NavMenu isVisible={isMenuOpen} closeMenu={closeMenu}>
          <div className="p-4 h-full bg-white dark:bg-zinc-800">
            <div className="pb-4 mb-4 flex flex-row items-center justify-between border-b border-zinc-600">
              <h1 className="text-lg font-medium">Menu</h1>

              <button onClick={closeMenu} className="p-2">
                <FontAwesomeIcon icon={faXmark} size="lg" />
              </button>
            </div>

            <div className="flex flex-col gap-2">
              <AppNavLink to="/videos" title="Videos" />
              <AppNavLink to="/channels" title="Channels" />
              <AppNavLink to="/schedule" title="Schedule" />

              {token && (
                <>
                  <AppNavLink to="/watching" title="Continue Watching" />
                  <AppNavLink to="/hidden" title="Hidden" />

                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button className="btn-primary-lg">
                      <FontAwesomeIcon icon={faUser} />
                    </Menu.Button>

                    <ContextMenuItems alignment={Alignment.Trailing}>
                      <ContextMenuNavLink href="/settings/appearance" title="Settings" />
                      <ContextMenuItem title="Sign Out" onClick={() => dispatch(signOut())} />
                    </ContextMenuItems>
                  </Menu>
                </>
              )}
            </div>
          </div>
        </NavMenu>

        <div className="flex flex-row items-center gap-2">
          <button onClick={openMenu} className="p-2">
            <FontAwesomeIcon icon={faBars} size="lg" />
          </button>

          <Link to="/">
            <h1>
              <img src="/assets/logo.png" alt="FOTS" className="h-10" />
            </h1>
          </Link>
        </div>

        <div className="flex flex-row items-center gap-2">
          {token && (
            <>
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="btn-primary-lg">
                  <FontAwesomeIcon icon={faUser} />
                </Menu.Button>

                <ContextMenuItems alignment={Alignment.Trailing}>
                  <ContextMenuNavLink href="/settings/appearance" title="Settings" />
                  <ContextMenuItem title="Sign Out" onClick={() => dispatch(signOut())} />
                </ContextMenuItems>
              </Menu>
            </>
          )}

          {token == null && (
            <button onClick={openModal} className="btn-primary-lg">
              Sign In
            </button>
          )}
        </div>
      </header>
    </>
  );
}
