import { useGetScheduleQuery } from 'common/api';
import ErrorMessage from 'components/shared/status/ErrorMessage';
import Loading from 'components/shared/status/Loading';
import ScheduleEntry from './ScheduleEntry';

export default function Schedule() {
  const { data, isLoading, error } = useGetScheduleQuery();

  return (
    <div>
      <Loading isVisible={isLoading} />
      <ErrorMessage isVisible={!isLoading && error != undefined} message="Error loading schedule!" />

      {data && (
        <section className="card p-4">
          <h1 className="text-xl font-bold pb-4">Schedule</h1>

          <div className="divide-y divide-zinc-200 dark:divide-zinc-700">
            {data.map((entry) => (
              <ScheduleEntry entry={entry} />
            ))}
          </div>
        </section>
      )}
    </div>
  );
}
